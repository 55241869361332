export const PRIMARY_MENU = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Playlists",
    link: "/playlists",
  },
  {
    label: "Tracks",
    link: "/tracks",
  },
  {
    label: "Artists",
    link: "/artists",
  },
  {
    label: "Albums",
    link: "/albums",
  },
  {
    label: "Tags",
    link: "/tags",
  },
];

export const SECONDARY_MENU = [
  {
    label: "Users",
    link: "/users",
  },
  {
    label: "Memberships",
    link: "/memberships",
  },
];
