import { StrictMode, Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./layouts/root";
import FlashScreen from "./components/flashscreen";
import ProtectedRoute from "./components/auth-wrapper";

const LoginPage = lazy(() => import("./pages/login"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const PlaylistsPage = lazy(() => import("./pages/playlists"));

const AlbumsPage = lazy(() => import("./pages/albums/index"));
const AlbumDetailsPage = lazy(() => import("./pages/albums/details"));
const AddAlbumPage = lazy(() => import("./pages/albums/new"));
const AlbumEditPage = lazy(() => import("./pages/albums/edit"));

const ArtistsPage = lazy(() => import("./pages/artists/index"));
const ArtistDetailsPage = lazy(() => import("./pages/artists/details"));
const AddArtistPage = lazy(() => import("./pages/artists/new"));
const ArtistEditPage = lazy(() => import("./pages/artists/edit"));

const TracksPage = lazy(() => import("./pages/tracks/index"));
const TrackDetailsPage = lazy(() => import("./pages/tracks/details"));
const AddTrackPage = lazy(() => import("./pages/tracks/new"));
const TrackEditPage = lazy(() => import("./pages/tracks/edit"));

const TagsPage = lazy(() => import("./pages/tags"));
const MembershipsPage = lazy(() => import("./pages/memberships"));
const UsersPage = lazy(() => import("./pages/users"));
const ErrorPage = lazy(() => import("./pages/error"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/playlists",
        element: (
          <ProtectedRoute>
            <PlaylistsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/tags",
        element: <TagsPage />,
      },
      {
        path: "/albums",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <AlbumsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ":id/details",
            element: (
              <ProtectedRoute>
                <AlbumDetailsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <ProtectedRoute>
                <AlbumEditPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "new",
            element: (
              <ProtectedRoute>
                <AddAlbumPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/artists",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <ArtistsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ":id/details",
            element: (
              <ProtectedRoute>
                <ArtistDetailsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <ProtectedRoute>
                <ArtistEditPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "new",
            element: (
              <ProtectedRoute>
                <AddArtistPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/tracks",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <TracksPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ":id/details",
            element: (
              <ProtectedRoute>
                <TrackDetailsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <ProtectedRoute>
                <TrackEditPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "new",
            element: (
              <ProtectedRoute>
                <AddTrackPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/memberships",
        element: (
          <ProtectedRoute>
            <MembershipsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/users",
        element: (
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <Suspense fallback={<FlashScreen />}>
      <RouterProvider router={router} />
    </Suspense>
  </StrictMode>
);
