import { Box } from "@mui/material";

const FlashScreen = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 48px)",
      }}
    >
      <img src="/logo192.png" alt="UnMusic" height={64} width={64} />
    </Box>
  );
};

export default FlashScreen;
