import { Fragment, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import StyleIcon from "@mui/icons-material/Style";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import HomeIcon from "@mui/icons-material/Home";
import {
  AuthStateContext,
  AuthDispatchContext,
  signOut,
} from "../contexts/auth";
import { PRIMARY_MENU, SECONDARY_MENU } from "../constants/common";

export default function TemporaryDrawer() {
  const { user } = useContext(AuthStateContext);
  const authDispatch = useContext(AuthDispatchContext);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    signOut(authDispatch);
    navigate("/login");
  };

  const handleMenuItem = (link) => {
    setIsOpen(false);
    navigate(link);
  };

  const getIconForMenuLink = (link) => {
    switch (link) {
      case "/":
        return <HomeIcon />;
      case "/playlists":
        return <SubscriptionsIcon />;
      case "/tracks":
        return <LibraryMusicIcon />;
      case "/artists":
        return <RecordVoiceOverIcon />;
      case "/tags":
        return <StyleIcon />;
      case "/users":
        return <PeopleAltIcon />;
      case "/memberships":
        return <PaymentsIcon />;
      default:
        return <FolderCopyIcon />;
    }
  };

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setIsOpen(true)}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Link to="/" sx={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src="/unmusic-logo.svg"
                    alt="UnMusic"
                    width={154}
                    height={30}
                  />

                  <Typography
                    variant="caption"
                    sx={{ marginLeft: "4px" }}
                    color="#999"
                  >
                    Admin
                  </Typography>
                </Box>
              </Link>

              {user && (
                <Box>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer
        variant="temporary"
        // onBackdropClick={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            {PRIMARY_MENU.map((menu, index) => (
              <ListItem
                key={menu.link}
                onClick={() => handleMenuItem(menu.link)}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>{getIconForMenuLink(menu.link)}</ListItemIcon>
                  <ListItemText primary={menu.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {SECONDARY_MENU.map((menu, index) => (
              <ListItem
                key={menu.link}
                onClick={() => handleMenuItem(menu.link)}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>{getIconForMenuLink(menu.link)}</ListItemIcon>
                  <ListItemText primary={menu.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Fragment>
  );
}
