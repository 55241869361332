import { useReducer, createContext } from "react";
import { getJSON, putJSON, postJSON } from "utils/axios";
import { enqueueSnackbar } from "notistack";

const initialState = {
  list: {
    isLoading: false,
    data: null,
    error: null,
  },
  detail: {
    isLoading: false,
    isUpdating: false,
    data: null,
    error: null,
  },
};

export const TracksStateContext = createContext();
export const TracksDispatchContext = createContext();

const ACTION_TYPES = {
  GET_TRACKS_REQUEST: "GET_TRACKS_REQUEST",
  GET_TRACKS_SUCCESS: "GET_TRACKS_SUCCESS",
  GET_TRACKS_FAILURE: "GET_TRACKS_FAILURE",
  CREATE_TRACK_REQUEST: "CREATE_TRACK_REQUEST",
  CREATE_TRACK_SUCCESS: "CREATE_TRACK_SUCCESS",
  CREATE_TRACK_FAILURE: "CREATE_TRACK_FAILURE",
  UPDATE_TRACK_REQUEST: "UPDATE_TRACK_REQUEST",
  UPDATE_TRACK_SUCCESS: "UPDATE_TRACK_SUCCESS",
  UPDATE_TRACK_FAILURE: "UPDATE_TRACK_FAILURE",
  GET_TRACK_DETAIL_REQUEST: "GET_TRACK_DETAIL_REQUEST",
  GET_TRACK_DETAIL_SUCCESS: "GET_TRACK_DETAIL_SUCCESS",
  GET_TRACK_DETAIL_FAILURE: "GET_TRACK_DETAIL_FAILURE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_TRACKS_REQUEST:
      return {
        ...state,
        list: {
          data: null,
          error: null,
          isLoading: true,
        },
      };
    case ACTION_TYPES.GET_TRACKS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          isLoading: false,
          error: null,
        },
      };
    case ACTION_TYPES.GET_TRACKS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          isLoading: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPES.CREATE_TRACK_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
        },
      };
    case ACTION_TYPES.CREATE_TRACK_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
        },
      };
    case ACTION_TYPES.CREATE_TRACK_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
        },
      };
    case ACTION_TYPES.UPDATE_TRACK_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: true,
        },
      };
    case ACTION_TYPES.UPDATE_TRACK_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: false,
        },
      };
    case ACTION_TYPES.UPDATE_TRACK_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          isEditing: false,
        },
      };
    case ACTION_TYPES.GET_TRACK_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
        },
      };
    case ACTION_TYPES.GET_TRACK_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case ACTION_TYPES.GET_TRACK_DETAIL_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: null,
          error: action.payload.error,
          isLoading: false,
        },
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const TracksProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <TracksDispatchContext.Provider value={dispatch}>
      <TracksStateContext.Provider value={state}>
        {children}
      </TracksStateContext.Provider>
    </TracksDispatchContext.Provider>
  );
};

export const getAllTracks = async (dispatch, page = 1) => {
  dispatch({
    type: ACTION_TYPES.GET_TRACKS_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/tracks`
    );
    dispatch({
      type: ACTION_TYPES.GET_TRACKS_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.GET_TRACKS_FAILURE,
      payload: {
        error: error || "Sorry, unable to fetch tracks!",
      },
    });
  }
};

export const getTrackDetail = async (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_TRACK_DETAIL_REQUEST,
  });
  try {
    const response = await getJSON(
      `${process.env.REACT_APP_API_BASE_URL}/tracks/${id}`
    );
    dispatch({
      type: ACTION_TYPES.GET_TRACK_DETAIL_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.GET_TRACK_DETAIL_FAILURE,
      payload: {
        error: error || "Oops, something went wrong!",
      },
    });
  }
};

export const createTrack = async (dispatch, values) => {
  dispatch({
    type: ACTION_TYPES.CREATE_TRACK_REQUEST,
  });
  try {
    const response = await postJSON(
      `${process.env.REACT_APP_API_BASE_URL}/tracks`,
      values
    );
    if (response) {
      dispatch({
        type: ACTION_TYPES.CREATE_TRACK_SUCCESS,
      });
      enqueueSnackbar("Track created successfully!", { variant: "success" });
      const id = response?.data?.id;
      return getTrackDetail(dispatch, id);
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.CREATE_TRACK_FAILURE,
    });
    enqueueSnackbar(error?.message, { variant: "error" });
  }
};

export const updateTrack = async (dispatch, id, values) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_TRACK_REQUEST,
  });
  try {
    const response = await putJSON(
      `${process.env.REACT_APP_API_BASE_URL}/tracks/${id}`,
      values
    );
    if (response) {
      dispatch({
        type: ACTION_TYPES.UPDATE_TRACK_SUCCESS,
      });
      return getTrackDetail(dispatch, id);
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: ACTION_TYPES.UPDATE_TRACK_FAILURE,
    });
  }
};

export default TracksProvider;
