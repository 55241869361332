import { cloneElement } from "react";
import Box from "@mui/material/Box";

const Main = ({ children, ...allOtherProps }) => {
  return (
    <Box sx={{ minHeight: "calc(100vh - 116px)", padding: "24px" }}>
      {cloneElement(children, { ...allOtherProps })}
    </Box>
  );
};

export default Main;
