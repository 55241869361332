import { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import Box from "@mui/material/Box";
import AuthProvider, {
  AuthDispatchContext,
  AuthStateContext,
  checkValidity,
} from "contexts/auth";
import TracksProvider from "contexts/tracks";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "components/header";
import Main from "components/main";
import Footer from "components/footer";
import { Typography } from "@mui/material";

const queryClient = new QueryClient();

const RootWrapper = () => {
  const { isCheckingValidity } = useContext(AuthStateContext);
  const dispatch = useContext(AuthDispatchContext);

  useEffect(() => {
    checkValidity(dispatch);
  }, [dispatch]);
  if (isCheckingValidity) {
    return <Typography variant="body">Loading...</Typography>;
  }

  return (
    <Box sx={{ background: "rgba(206, 219, 225, 0.29)" }}>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Box>
  );
};

function Root() {
  return (
    <>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <AuthProvider>
            <TracksProvider>
              <RootWrapper />
            </TracksProvider>
          </AuthProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </>
  );
}

export default Root;
