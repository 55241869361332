import { Typography, Container } from "@mui/material";

const Footer = () => {
  const date = new Date();
  return (
    <Container
      sx={{
        padding: "16px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography variant="caption">
        <span>&copy;&nbsp;</span>
        <a href="https://admin.unmusic.app">admin.unmusic.app</a>
        <span>&nbsp;</span>
        <span>{date?.getFullYear()}</span>
      </Typography>
    </Container>
  );
};

export default Footer;
